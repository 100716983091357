import React from "react";
import "./Footer.css";


function Footer() {
    return (
        <footer className="footer">
            
            <div className='footer__container'>
                <p className='footer__copyright'>&copy;EMU-MAX, 2020</p>

                <nav className='footer__list-link'>
                    <a className='footer__link' href='#emulators'>Каталог</a>
                    <a className='footer__link' href='#buy'>Где купить</a>
                    <a className='footer__link' href='#contacts'>Контакты</a>
                </nav>
                
            </div>
        </footer>
    );
}

export default Footer;