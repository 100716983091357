import React from "react";
import "./Promo.css";

function Promo() {
    return(
        <>
        <section className="promo">
            <div className="promo__text">
                <h1 className="promo__title">
                    Эмулятор Emu-Max
                </h1>
                <p className="promo__subtitle">
                    Универсальное электронное устройство, 
                    полностью дублирующее логику работы исправной системы SCR 
                    транспортного средства в&nbsp;зависимости от&nbsp;марки и&nbsp;модели автомобиля.
                </p>
            </div>
            <button className="promo__button">
                <a href="#emulators" className="promo__button_link">Узнать больше</a>
            </button>
        </section>
        </>
    )
}

export default Promo;