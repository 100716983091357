import React from 'react';
import './Header.css'
import headerLogo from '../../image/logo_emu_max.svg';
import Navigation from '../Navigation/Navigation';

function Header() {
    return (
        <div className='header'>
            <img className="header__logo header-main__logo" src={headerLogo} alt="логотип" />
            <Navigation />
        </div>
    );
  }
  
  export default Header;