import React from 'react';
import './Buy.css'
import logo2 from '../../image/ts_logo.jpg';
import logo3 from '../../image/logo_scanLand.svg';

function Buy() {
    return (
        <div className='buy' id="buy">
            <h2 className='buy__title'>ГДЕ КУПИТЬ EMU-MAX</h2>

            <div className='buy__grid'>
                <div className='buy__element'>
                    <img className='buy__img' src={logo2} />
                    <a href="https://trucksolution.ru/" target="_blank" className='buy__link'>TRUCKSOLUTION.RU</a>
                    <p className='buy__subtitle'>Россия, Москва</p>
                </div>

                <div className='buy__element'>
                    <img className='buy__img' src={logo3} />
                    <a href="https://scanland.ru/" target="_blank" className='buy__link'>SCANLAND.RU</a>
                    <p className='buy__subtitle'>Россия, Москва</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Buy;