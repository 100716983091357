import React from 'react';
import './Benefits.css'
import benefits1 from '../../image/benefits_1.png';
import benefits2 from '../../image/benefits_2.png';
import benefits3 from '../../image/benefits_3.png';
import benefits4 from '../../image/benefits_4.png';
import benefits5 from '../../image/benefits_5.png';
import benefits6 from '../../image/benefits_6.png';

function Benefits() {
    return (
        <div className='benefits'>
            <h2 className='benefits__title'>ПРЕИМУЩЕСТВА ЭМУЛЯТОРА EMU-MAX</h2>
            
            <div className='benefits__grid'>
                <div className='benefits__element'>
                    <img src={benefits1} className='benefits__img' />
                </div>

                <div className='benefits__element'>
                    <img src={benefits2} className='benefits__img' />
                </div>

                <div className='benefits__element'>
                    <img src={benefits3} className='benefits__img' />
                </div>

                <div className='benefits__element'>
                    <img src={benefits4} className='benefits__img' />
                </div>

                <div className='benefits__element'>
                    <img src={benefits5} className='benefits__img' />
                </div>

                <div className='benefits__element'>
                    <img src={benefits6} className='benefits__img' />
                </div>
                

            </div>
        </div>
    );
  }
  
  export default Benefits;